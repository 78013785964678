import { Offline, Online } from "react-detect-offline";
import React, { useState, useEffect } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "../style/nfts.css";

const NFTDisplay = () => {

  const [open, setOpen] = React.useState(false);

  const nfts = [
    {"collection": "bayc", "number": "2794", "url": "https://img.seadn.io/files/f687d9607ab1267e0e1877ab9db8e729.png?fit=max&w=1000", "name": "PieBorg", "wallet": "0xe6Db9672f40cD7394C4d98eBD841A2a4bbEC52E4"},
    {"collection": "bayc", "number": "7156", "url": "https://img.seadn.io/files/3edbc5bd7c2dd03a8455379b2ef69bd0.png?fit=max&w=1000", "name": "Ape-Out", "wallet": "0xeE83525eDF89F42813b513a6B10720e0b58f7c88"},
    {"collection": "bayc", "number": "236", "url": "https://img.seadn.io/files/453fa7a97d4005bdab30cf983d408cf4.png?fit=max&w=1000", "name": "MLE-EYEZ", "wallet": "0x88eC264feD1dd5AddE9150B3919726eB810761dC"},
    {"collection": "bayc", "number": "3930", "url": "https://img.seadn.io/files/965bfc9d0302b9de7b91d83f6944b10c.png?fit=max&w=1000", "name": "BabyBorg", "wallet": "0x248E3f667d1c4Dfde7d6fbeED82502B992a7CCB7"},
    {"collection": "bayc", "number": "2935", "url": "https://img.seadn.io/files/82ac3fdb7103c5e5bb4c3eefdf2f3bbb.png?fit=max&w=1000", "name": "BabyBorg", "wallet": "0x248E3f667d1c4Dfde7d6fbeED82502B992a7CCB7"},
    {"collection": "bayc", "number": "4869", "url": "https://img.seadn.io/files/87d7455013673868fd8bf9c3efc3cf9f.png?fit=max&w=1000", "name": "BabyBorg", "wallet": "0x248E3f667d1c4Dfde7d6fbeED82502B992a7CCB7"},
    {"collection": "mayc", "number": "10472", "url": "https://img.seadn.io/files/702f9ce2fa24762841b557bc40f7e7f0.png?fit=max&w=1000", "name": "MLE-EYEZ", "wallet": "0x88eC264feD1dd5AddE9150B3919726eB810761dC"},
    {"collection": "mayc", "number": "10473", "url": "https://img.seadn.io/files/5da9e15cf6d9eb22ecbd751943d510ff.png?fit=max&w=1000", "name": "MLE-EYEZ", "wallet": "0x88eC264feD1dd5AddE9150B3919726eB810761dC"},
    {"collection": "bakc", "number": "236", "url": "https://img.seadn.io/files/c40d5b74a45039a9495b569bf8400f7f.png?fit=max&w=1000", "name": "MLE-EYEZ", "wallet": "0x88eC264feD1dd5AddE9150B3919726eB810761dC"},
    {"collection": "koda", "number": "27150", "url": "https://img.seadn.io/files/895cbff60c631f931b422032957ccb90.jpg?fit=max&w=1000", "name": "MLE-EYEZ", "wallet": "0x88eC264feD1dd5AddE9150B3919726eB810761dC"},
    {"collection": "koda", "number": "27151", "url": "https://img.seadn.io/files/43d82882889868c99bd83b54d823ce12.jpg?fit=max&w=1000", "name": "MLE-EYEZ", "wallet": "0x88eC264feD1dd5AddE9150B3919726eB810761dC"},
    {"collection": "koda", "number": "236", "url": "https://img.seadn.io/files/f911c295a6e350936d74e14dacca2ea5.jpg?fit=max&w=1000", "name": "MLE-EYEZ", "wallet": "0x88eC264feD1dd5AddE9150B3919726eB810761dC"},
    {"collection": "koda", "number": "2794", "url": "https://img.seadn.io/files/be45f943232b58ed53876195faf3d799.jpg?fit=max&w=1000", "name": "PieBorg", "wallet": "0xe6Db9672f40cD7394C4d98eBD841A2a4bbEC52E4"},
    {"collection": "bakc", "number": "8736", "url": "https://img.seadn.io/files/3730af6411d836ae0a909759cb553d6e.png?fit=max&w=1000", "name": "PieBorg", "wallet": "0xe6Db9672f40cD7394C4d98eBD841A2a4bbEC52E4"},
    {"collection": "koda", "number": "2935", "url": "https://img.seadn.io/files/b9ee2c34a7ca0fb105fb5e4abf993ded.jpg?fit=max&w=1000", "name": "BabyBorg", "wallet": "0x248E3f667d1c4Dfde7d6fbeED82502B992a7CCB7"},
    {"collection": "koda", "number": "4869", "url": "https://img.seadn.io/files/428a69ecb13b7c570fbc44a6d946a57c.jpg?fit=max&w=1000", "name": "BabyBorg", "wallet": "0x248E3f667d1c4Dfde7d6fbeED82502B992a7CCB7"},
    {"collection": "koda", "number": "3930", "url": "https://img.seadn.io/files/229d910325ab57e9cc07e2d2a3199db2.jpg?fit=max&w=1000", "name": "BabyBorg", "wallet": "0x248E3f667d1c4Dfde7d6fbeED82502B992a7CCB7"},
    {"collection": "koda", "number": "7156", "url": "https://img.seadn.io/files/0fc631ccf0cf3aab5c0e8589279a8b3f.jpg?fit=max&w=1000", "name": "BabyBorg", "wallet": "0x248E3f667d1c4Dfde7d6fbeED82502B992a7CCB7"},
  ]

  let slides = []
  for (let i =0; i < nfts.length; i++) {
    const nft = nfts[i];
    slides.push({src: nft.url})
  }
  return (
    <>
      <div className="nfts-container" onClick={() => setOpen(true)}>
          <h3>NFTs</h3>
            <div className="nfts">
              <img 
                className="nfts-image"
                src="https://img.seadn.io/files/f687d9607ab1267e0e1877ab9db8e729.png?fit=max&w=1000"  
              />

              <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides}
              />
          </div>
      </div>
    </>
  );
};

export default NFTDisplay;