import React, { Component }  from 'react';
import SolanaBeachWalletBalance from "./SolanaBeachWalletBalance";
import SolanaBeachWalletStakeRewards from "./SolanaBeachWalletStakeRewards";
import MeritCircleInfo from "./MeritCircleInfo";
import LidoInfo from "./LidoInfo";
import NFTDisplay from "./NFTDisplay";

const FinalSolanaBeachWallet = () => {
    const solanaUrl = "https://cryptologos.cc/logos/solana-sol-logo.png"
    return (
      <>
        <SolanaBeachWalletBalance />
        <MeritCircleInfo />
        <LidoInfo />
        <NFTDisplay />
      </>
    );
}

export default FinalSolanaBeachWallet;