import "../style/solanabeach.css";
import "../style/meritCircle.css";
import { Offline, Online } from "react-detect-offline";
import React, { useState, useEffect } from "react";
import axios from "axios";

const MeritCircleInfo = () => {
  const [sol, setSol] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      setLoading(true);
      axios
        .get("https://cosimo-api.herokuapp.com/api/v1/satoshi_sol_balance?limit=1&filter_by=symbol&filter_by_value=MC&api_key=aSmBpNbftAU0RZAkzXthU5lCIJHHNqGd")
        .then((res) => {
          setSol(res.data);
          setLoading(false);
        })
        .catch((err) => { console.log(err); });
    }, []);

    return (
        <>
            <div className="merit-circle-align">
                <h3>Merit Circle</h3>
                <div className="merit-circle-wallet">
                    <table className="merit-circle-card">
                    <thead>
                        <tr>
                            <th><h4 className="table-title">Balance</h4></th>
                            <th><h4 className="table-title">Earned</h4></th>
                        </tr>
                    </thead>
                    <tbody>
                      <tr style={{display: "none"}}><td colSpan={3}>&nbsp;</td></tr>
                      {loading ? (
                        <div className="loader-container"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                      ) : (
                        <>
                          {sol.map((sol) => {
                            let new_balance = sol.balance.toLocaleString("en-US", {maximumFractionDigits: 2,});
                            if (sol.balance > 1000000) {
                              new_balance = (sol.balance / 1000000).toLocaleString("en-US", {maximumFractionDigits: 2,}) + "mm";
                            }
                            let new_totalearned = (sol.unclaimed_rewards_balance).toLocaleString("en-US", {maximumFractionDigits: 2,});
                            if (sol.unclaimed_rewards_balance > 1000000) {
                              new_totalearned = (sol.unclaimed_rewards_balance / 1000000).toLocaleString("en-US", {maximumFractionDigits: 2,}) + "mm";
                            }
                            let displayLocked = (sol.locked_rewards_balance).toLocaleString("en-US", {maximumFractionDigits: 2,});
                            if (sol.locked_rewards_balance > 1000000) {
                                displayLocked = (sol.locked_rewards_balance / 1000000).toLocaleString("en-US", {maximumFractionDigits: 2,}) + " mm";
                            }
                            const displayApr = (sol.apr).toLocaleString('en-US', {maximumFractionDigits: 2});

                            return (
                              <tr>
                                <td className="table-balanceformat">
                                  <p className="table-maincolor">{new_balance}</p>
                                  <p className="table-label">MC</p>
                                </td>
                                <td className="table-balanceformat">
                                  <p className="table-maincolor">{displayLocked}</p>
                                  <p className="table-label">LOCKED MC</p>
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      )}
                    </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}

export default MeritCircleInfo;